import { LanguageInterface } from '@/i18n/labels/type'

export const en: LanguageInterface = {
  error_pages: {
    title: 'Error {{status}}',
    status: {
      404: 'The page you are looking for does not exist.',
      500: 'An unexpected error happened. Please, try again!',
    },
  },
  translation: {
    follow_up: {
      title: 'Follow Up for {{name}}',
      new_follow_up: 'New follow up',
      system: 'System',
      nothing_to_show: 'Nothing to show',
      msgs: {
        SYS_COLLABORATOR_INACTIVATED: 'Employee inactivated',
        SYS_COLLABORATOR_ACTIVATED: 'Employee activated',
      },
    },
    messages: {
      employee_inactivated: 'The inactivation has been scheduled.',
      form_contains_errors: 'Please, fix the errors on the form',
      updating_leaving_schedule:
        'The inactivation is already scheduled. You changes made here will update the current schedule.',
      employee_saved: 'The employee has been saved!',
      no_content: 'No data to display.',
      confirm_exit_lose_data: 'Any information you filled will be lost.',
    },
    pages: {
      dashboard: 'Dashboard',
      analytics: 'Analytics',
      settings: 'Settings',
      career_plan: 'Career Plan',
      career_plan_assign: 'Assign levels',
      profiles: 'Manage profiles',
      career_plan_management: 'Management',
      reports: 'Reports',
      manage_reports: 'Mange reports',
      goals: 'Goals',
    },
    enums: {
      gender: {
        FEMALE: 'Female',
        MALE: 'Male',
      },
      status: {
        ACTIVE: 'Active',
        OFF_BOARDING: 'Offboarding',
        ONBOARDING: 'Onboarding',
        INACTIVE: 'Inactive',
      },
      billing: {
        DAILY: 'Daily',
        MONTHLY: 'Monthly',
      },
      language: {
        EN: 'English',
        PT: 'Portuguese',
      },
    },
    labels: {
      ok: 'OK',
      yes: 'Yes',
      no: 'No',
      cancel: 'Cancel',
      save: 'Save',
      edit: 'Edit',
      timeline: 'View history',
      confirm: 'Confirm',
      go_back: 'Go back',
      action_forbidden: 'You cannot perform this action.',
      filter: 'Filter',
      create_employee: 'Create employee',
      search: 'Search',
      noRows: 'No rows',
      rowsSelected_one: '1 row selected',
      rowsSelected_other: '{{count}} rows selected',
      loading: 'Loading...',
      type_to_search: 'Type to search...',
      apply: 'Apply',
      export: 'Export',
      updated: 'Updated in',
      sortIconLabel: 'Sort',
      dataFormat: "d LLLL '-' HH'h'mm",
      dataFormatNoHours: "MM'/'dd'/'yyyy",
      copied: 'Copied!',
      no_items_exist: 'No items to display!',
      no_results_found: 'No results found!',
      warning: 'Warning',
      form_contains_errors:
        'There are invalid fields on the form. Please, check them and try again!',
      clear_selection: 'Clear selection',
      row_selected: 'row selected',
      rows_selected: 'rows selected',
      select_all: 'Select all',
    },
    topbar: {
      logout: 'Logout',
    },
    categories: {
      company: 'Company information',
      contract: 'Contract information',
      personal: 'Personal information',
    },
    form_tabs: {
      company_info: 'Prime Info',
      contract_info: 'Contract',
      player_info: 'Player Info',
    },
    form_error_message: {
      personal_email: 'Enter a valid personal e-mail. e.g. xxxx@gmail.com',
      prime_email: 'Enter a valid prime e-mail. e.g. xxxx@primeit.pt',
      currency: 'Enter a valid number.',
      name: 'Name must contain at least 1 character.',
      zip: 'Enter a valid zip code. Only numbers and "-".',
      personal_phone:
        'Enter a valid personal phone number with country code. e.g. +351...',
      prime_phone:
        'Enter a valid prime phone number with country code. e.g. +351...',
    },
    global_table: {
      labels: {
        clear_filters: 'Clear filters',
        clear_all_filters: 'Clear all filters',
        open_quick_view: 'Open Quick View',
        open_new_tab: 'Open New Tab',
        edit: 'Edit',
        inactivate: 'Inactivate',
        no_filters_applied: 'No filters applied',
        results_found_one: '1 result found',
        results_found_other: '{{count}} results found',
        results_found_zero: 'No results found',
      },
      columns: {
        nationality: 'Nationality',
        client: 'Client',
        department: 'Department',
        sage_number: 'Sage',
        name: 'Full Name',
        personal_email: 'Personal Email',
        personal_phone: 'Personal Phone',
        birth_date: 'Birth Date',
        age: 'Age',
        gender: 'Gender',
        dependents: 'Dependents',
        dependents_birth_date: 'Birth date',
        dependents_age: 'Age',
        dependents_name: 'Name',
        country: 'Country',
        region: 'Region',
        residence: 'Residence',
        postal_code: 'Postal Code',
        language: 'Language',
        role: 'Role',
        prime_email: 'Prime Email',
        prime_phone: 'Prime Phone',
        businessManager: 'Business Manager',
        businessUnitManager: 'Business Unit Manager',
        people_officer: 'HR Business Partner',
        team: 'Team',
        office: 'Office',
        managementOffice: 'Management Office',
        project: 'Project',
        contract: 'Contract',
        iefp: 'IEFP',
        contract_started_at: 'Contract Start At',
        end_contract_experimental_period: 'End Contract Experimental Period',
        end_contract: 'End Contract',
        leaving_reason: 'Leaving Reason',
        rate: 'Rate',
        billing: 'Billing',
        coef2: 'COEF2',
        status: 'Status',
        salary_component: 'Salary Component',
        salary_component_base_value: 'Base value',
        salary_component_child_ticket: 'Child Ticket',
        salary_component_iht: 'IHT',
        salary_component_rate: 'Rate',
        salary_component_ac: 'AC',
        salary_component_foodTicket: 'Food ticket',
        salary_component_carTicket: 'Car ticket',
        salary_component_una: 'UNA',
        observations: 'Observations',
        skill: 'Skill',
        project_start_date: 'Start date',
        invoice_start_date: 'Invoice start date',
        financialStatus: 'Financial Status',
        paidLeave: 'Paid leave',
        contact: 'Contact',
        personal_info: 'Personal information',
        work_contact: 'Work contact',
        contractEndDate: 'Contract End Date',
        follow_up: 'Follow Up',
        tenant: 'Tenant',
        externalId: '#{{name}}',
        first_day_of_contract: 'First day of work',
      },
      export_files: {
        all_selected: 'All selected',
        some_selected_one: '{{count}} of {{totalFilesNumber}} records selected',
        some_selected_other:
          '{{count}} of {{totalFilesNumber}} records selected',
        specific_selected_one: '{{count}} selected',
        specific_selected_other: '{{count}} selected',
        export: 'Export',
        excel: 'Excel',
        csv: 'CSV',
        all_pages_selected: 'All pages selected',
        select_all_pages: 'Select all pages',
      },
      row_count: {
        all_selected: 'All selected',
        some_selected_one: '{{count}} selected',
        some_selected_other: '{{count}} selected',
        select_all_action: 'Select all employees',
        all_selected_undo: 'Undo',
      },
    },
    analytics: {
      table: {
        employees_by_department: 'People per department',
        status: 'Status',
        goal: 'Goal {{year}}',
        consultants: 'Consultants',
      },
      lines: {
        total_consultants_invoiced_lisbon: 'TOTAL CONSULTANTS INVOICED LISBON',
        total_consultants_invoiced_porto: 'TOTAL CONSULTANTS INVOICED PORTO',
        total_ic: 'TOTAL IC',
        total_prime_solution_interco: 'TOTAL PRIME SOLUTIONS',
        total_unpaid_leave: 'TOTAL OFFBOARDINGS',
        total_not_invoiced_in_project: 'TOTAL NOT INVOICED IN PROJECT',
        total_not_invoiced_maternity_paternity_leave:
          'TOTAL NOT INVOICED MATERNITY PATERNITY LEAVE',
        total_not_invoiced_sick_leave: 'TOTAL NOT INVOICED SICK LEAVE',
        total_consultants: 'TOTAL CONSULTANTS',
        total_effectives: 'TOTAL EFFECTIVES',
        total_company: 'TOTAL COMPANY',
        effectives: 'EFFECTIVES',
        freelance: 'FREELANCE',
        in_project: 'IN PROJECT',
        trainees: 'TRAINEE',
        goal_invoiced_lisbon: 'INVOICED LISBON',
        goal_difference_lisbon: 'DIFFERENCE LISBON',
        goal_invoiced_porto: 'INVOICED PORTO',
        goal_difference_porto: 'DIFFERENCE PORTO',
        goal_invoiced_total: 'INVOICED TOTAL',
        goal_difference: 'DIFFERENCE',
      },
    },
    inactive_modal: {
      title: 'Inactivate Employee',
      modal_description:
        'You are scheduling the inactivation of <highlight>{{employeeName}}</highlight>. To continue, provide the following information:',
      schedule_inactivation_button: 'Schedule inactivation',
      cancel_button: 'Cancel',
    },
  },
  profile_management_page: {
    labels: {
      search: 'Search',
      submit_change: 'Done',
      edit: 'Edit',
      add_permission: 'Add',
      reset_button: 'Reset to default',
      filter_button_label: 'Filters',
      add_profile_title: 'Add {{name}}',
      apply_button: 'Apply',
      no_selected_item: 'No items selected!',
      clear_filter: 'Clear filter',
      selected_items: 'Selected items',
      change_role: {
        title: 'Change access',
        description:
          'Are you sure you want to change <highlight>{{name}}</highlight> access from <highlight>{{oldRole}}</highlight> to <highlight>{{currRole}}</highlight>?',
        cancel: 'Cancelar',
        confirm: 'Confirmar',
      },
      reset_profile: {
        title: 'Remove access',
        cancel: 'Cancel',
        confirm: 'Yes',
        message:
          'Are you sure you want to remove <highlight>{{role}}</highlight> access of <highlight>{{name}}</highlight>?',
      },
    },
    columns: {
      email: 'E-mail',
      tagLine: 'Role',
      fullName: 'Name',
      moduleRole: 'Permission',
    },
  },
  career_plan: {
    labels: {
      title: 'Career plan management',
      add: 'Career Plan',
      edit: 'Edit',
      search: 'Search',
      search_placeholder: 'Type to search...',
      clear_filter: 'Clear filter',
      clear_filters: 'Clear filters',
      selected_items: 'Selected items',
      done: 'Done',
      filters: 'Filters',
      no_filters: 'No filters selected.',
      no_item_selected: 'No options selected.',
      cancel: 'Cancel',
      save: 'Save',
      change_level: 'Change level',
      level_change: 'Level change',
    },
    columns: {
      name: 'Level',
      baseSalary: 'Base salary',
      career_level: 'Career Level',
      role: 'Role',
      department: 'Department',
    },
    messages: {
      form_field_errors: 'Please, check the following fields: {{fields}}',
      career_plan_added: 'The career plan has been successfully added!',
      error_saving_career_plan:
        'The career plan could not be saved. Please, try again!',
      career_plan_saved: 'The career plan has been saved!',
      one_level_required: 'The career plan needs at least one level.',
      confirm_change_level_zero:
        'Do you want to change the career level of <highlight>{{nome}}</highlight> to {{newRole}}?',
      confirm_change_level_one:
        'Do you want to change the career level of <highlight>{{nome}}</highlight> from {{oldRole}} to {{newRole}}?',
      level_changed: 'The career level has been updated.',
      career_plan_removed: 'The career plan has been removed.',
      career_plan_removal_error:
        'The career plan could not be removed. Please, try again!',
      confirm_career_plan_removal:
        'Are you sure you want to remove the career plan? This action cannot be undone.',
      cannot_delete_plan:
        'There are employees assigned to one or more levels from this career plan. You need to assign other levels to these employees at <link/>.',
      cannot_delete_level:
        'There are employees assigned to this career level. You need to assign other levels to these employees at <link/>',
    },
  },
  employee_history: {
    salary_zero: 'Salary unset',
    salary_one: 'Wage updated to £<b>{{value}}</b>',
    role_end_zero: 'Role unset',
    role_end_one: 'Exited role <b>{{name}}</b>',
    role_start_zero: 'Role unset',
    role_start_one: 'Promoted to <b>{{name}}</b>',
    contract_start_zero: 'Contract unset',
    contract_start_one: 'Contract updated to <b>{{name}}</b>',
    contract_end_zero: 'Contract unset',
    contract_end_one: 'Contract <b>{{name}}</b> ended',
    career_level_end_zero: 'Carrer level unset',
    career_level_end_one: 'End of career level <b>{{name}}</b>',
    career_level_start_zero: 'Carreer level unset',
    career_level_start_one: 'Carreer level promoted to <b>{{name}}</b>',
  },
  goals_page: {
    title: 'Goals',
    current: 'Current',
    expected: 'Expected',
    of: 'of',
    total: 'Total',
    completed: 'Completed',
    active: 'Active',
  },
  filters: {
    date_start: 'From',
    date_to: 'To',
    clear: 'Clear',
  },
}
